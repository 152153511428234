import React from "react";

const ExpertsSection = () => {
  return (
    <div style={styles.section}>
      <h2 style={styles.heading}>Meet Our Experts</h2>
      <p style={styles.subheading}>
        A team of professionals with expertise in diverse domains, here to guide and assist you.
      </p>
      <div style={styles.expertsContainer}>
        {/* Expert 1 */}
        <div style={styles.expertCard}>
          <h3 style={styles.expertName}>Abhishek Trivedi</h3>
          <p style={styles.expertRole}>Abhishek@calidtech.com</p>
          <p style={styles.expertRole}>+918828615492</p>
          <p style={styles.expertRole}>SME - Wireless & Switching</p>
          <div style={styles.contactOptions}>
            <a
              href="https://www.linkedin.com/in/abhishek-trivedi-47528528?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app"
              target="_blank"
              rel="noopener noreferrer"
              style={styles.contactLink}
            >
              <img
                src="https://upload.wikimedia.org/wikipedia/commons/8/81/LinkedIn_icon.svg"
                alt="LinkedIn"
                style={styles.icon}
              />
            </a>
            <a
  href="mailto:Abhishek@calidtech.com"
  style={styles.contactLink}
>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="#000"
    width="25px"
    height="25px"
  >
    <path d="M12 13.5L2 7v10h20V7l-10 6.5z" />
    <path d="M12 11L2 5h20l-10 6z" />
  </svg>
</a>

            <a
              href="https://wa.me/918828615492"
              target="_blank"
              rel="noopener noreferrer"
              style={styles.contactLink}
            >
              <img
                src="https://upload.wikimedia.org/wikipedia/commons/6/6b/WhatsApp.svg"
                alt="WhatsApp"
                style={styles.icon}
              />
            </a>
          </div>
        </div>
        {/* Expert 2 */}
        <div style={styles.expertCard}>
          <h3 style={styles.expertName}>Abhijeet Ghosh</h3>
          <p style={styles.expertRole}>Abhijeet@calidtech.com</p>
          <p style={styles.expertRole}>+919850883582</p>
          <p style={styles.expertRole}>SME - Factory Digital Transformation and IoT</p>
          <div style={styles.contactOptions}>
            <a
              href="https://www.linkedin.com/in/abhijeetghosh?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app"
              target="_blank"
              rel="noopener noreferrer"
              style={styles.contactLink}
            >
              <img
                src="https://upload.wikimedia.org/wikipedia/commons/8/81/LinkedIn_icon.svg"
                alt="LinkedIn"
                style={styles.icon}
              />
            </a>
            <a
  href="mailto:Abhijeet@calidtech.com"
  style={styles.contactLink}
>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="#000"
    width="25px"
    height="25px"
  >
    <path d="M12 13.5L2 7v10h20V7l-10 6.5z" />
    <path d="M12 11L2 5h20l-10 6z" />
  </svg>
</a>

            <a
              href="https://wa.me/919850883582"
              target="_blank"
              rel="noopener noreferrer"
              style={styles.contactLink}
            >
              <img
                src="https://upload.wikimedia.org/wikipedia/commons/6/6b/WhatsApp.svg"
                alt="WhatsApp"
                style={styles.icon}
              />
            </a>
          </div>
        </div>
        {/* Expert 3 */}
        <div style={styles.expertCard}>
          <h3 style={styles.expertName}>Debankur Basu</h3>
          <p style={styles.expertRole}>Debankur@calidtech.com</p>
          <p style={styles.expertRole}>+919764440551</p>
          <p style={styles.expertRole}>Technology Evangelist</p>
          <div style={styles.contactOptions}>
            <a
              href="https://www.linkedin.com/in/debankur-basu-1622423?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app"
              target="_blank"
              rel="noopener noreferrer"
              style={styles.contactLink}
            >
              <img
                src="https://upload.wikimedia.org/wikipedia/commons/8/81/LinkedIn_icon.svg"
                alt="LinkedIn"
                style={styles.icon}
              />
            </a>
            <a
  href="mailto:Debankur@calidtech.com"
  style={styles.contactLink}
>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="#000"
    width="25px"
    height="25px"
  >
    <path d="M12 13.5L2 7v10h20V7l-10 6.5z" />
    <path d="M12 11L2 5h20l-10 6z" />
  </svg>
</a>


            <a
              href="https://wa.me/919764440551"
              target="_blank"
              rel="noopener noreferrer"
              style={styles.contactLink}
            >
              <img
                src="https://upload.wikimedia.org/wikipedia/commons/6/6b/WhatsApp.svg"
                alt="WhatsApp"
                style={styles.icon}
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

const styles = {
  section: {
    width: "100%",
    padding: "50px 20px",
    backgroundColor: "#f3f4f6",
    textAlign: "center",
  },
  heading: {
    fontSize: "32px",
    marginBottom: "15px",
    color: "#222",
    fontFamily: "'Roboto', sans-serif",
    fontWeight: "700",
  },
  subheading: {
    fontSize: "18px",
    marginBottom: "40px",
    color: "#555",
    fontFamily: "'Roboto', sans-serif",
  },
  expertsContainer: {
    display: "flex",
    justifyContent: "center",
    gap: "30px",
    flexWrap: "wrap",
  },
  expertCard: {
    backgroundColor: "#fff",
    borderRadius: "15px",
    padding: "25px",
    width: "280px",
    boxShadow: "0 8px 20px rgba(0, 0, 0, 0.15)",
    textAlign: "center",
    transition: "transform 0.3s, box-shadow 0.3s",
  },
  expertName: {
    fontSize: "22px",
    fontWeight: "700",
    margin: "10px 0",
    color: "#333",
  },
  expertRole: {
    fontSize: "16px",
    color: "#777",
    marginBottom: "20px",
  },
  contactOptions: {
    display: "flex",
    justifyContent: "center",
    gap: "15px",
    marginTop: "15px",
  },
  contactLink: {
    display: "inline-block",
    textDecoration: "none",
  },
  icon: {
    width: "25px",
    height: "25px",
  },
};

export default ExpertsSection;
