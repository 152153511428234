import React, { useEffect, useState } from "react";
import "./EnterpriseDataCenterConsultingSubServicePage.css";
import EnterpriseServicePageNavBar from "../EnterpriseServiceNavbarPage";
import EnterpriseHeader from "../../EntertrpriseComponents/EnterpriseHeader";
import EnterpriseFooter from "../../EntertrpriseComponents/EnterpriseFooter";
import ComprehensiveAssessment from "../../../assets/images/datacenter.webp";
import StrategicPlanningAndDesign from "../../../assets/images/infrastructue.webp";
import OptimizationAndEfficiencyImprovement from "../../../assets/images/scalability.webp";
import Whatsapp from "../../../Common Components/Whatsapp";
import SwitchtoManufacturing from "../../EntertrpriseComponents/SwitchtoManufacturing";
import ContactUsBot from "../../../Common Components/ContactUsBot";
import OnPremiseDC from "../../../assets/pdf/On-PremiseDC.pptx";
import EnquiryPopup from "../../../Common Components/EnquiryPopup";
import { Helmet } from 'react-helmet';
import ContactButtons from "../../../Common Components/ContactButtons";
import CallMePopup from "../../../Common Components/CallMePopup";
import ShowMoreIcons from "../../../Common Components/ShowMoreIcons";

function EnterpriseDataCenterConsultingSubServicePage() {
  const toggleEnquiryPopup = () => {
    setIsEnquiryPopupOpen(!isEnquiryPopupOpen);
  };
  const [isEnquiryPopupOpen, setIsEnquiryPopupOpen] = useState(false);

  return (
    <div className="enterprise-service-page">
      <Helmet>
        <title>Calid Technologies - Enterprise Data Center - Juniper Mist, HPE/ARUBA, Ruckus, Cisco, Switches, Wireless Access Point, HPE Server, Dell Server, Dell Storage, Lenovo Server, Palo Alto Firewall, FortiGate, FortiGate Firewall, Sophos Firewall, Captive Portal Server, Cambium Network Products, Radwin Products, Mimosa Networks Products, Altai Products - Dealer Distributor in Pune, Mumbai, Maharashtra, Vadodara, Surat, Ahmedabad, Gujarat, Indore, MP, Jaipur, Rajasthan, Noida, Gurugram, Delhi, Kolkata, West Bengal, Bhubaneswar, Orissa, Hyderabad, Telangana, Vishakhapatnam, AP, Chennai, Coimbatore, TN, Bengaluru, Karnataka, India</title>
        <meta name="description" content="Your website description here" />
        <meta name="keywords" content="keyword1, keyword2, keyword3" />
        <meta name="author" content="Your Name or Company" />
        <meta name="robots" content="index, follow" />

        <meta property="og:type" content="website" />
        <meta property="og:title" content="Your Page Title" />
        <meta property="og:description" content="Your website description here" />
        <meta property="og:image" content="URL to your image" />
        <meta property="og:url" content="https://www.yourwebsite.com" />
        <meta property="og:site_name" content="Your Website Name" />
        <meta property="fb:app_id" content="Your Facebook App ID" />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Your Page Title" />
        <meta name="twitter:description" content="Your website description here" />
        <meta name="twitter:image" content="URL to your image" />
        <meta name="twitter:site" content="@YourTwitterHandle" />
        <meta name="twitter:creator" content="@YourTwitterHandle" />

        <meta itemprop="name" content="Your Page Title" />
        <meta itemprop="description" content="Your website description here" />
        <meta itemprop="image" content="URL to your image" />

        <meta name="mobile-web-app-capable" content="yes" />
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta name="apple-mobile-web-app-status-bar-style" content="black-translucent" />
        <meta name="apple-mobile-web-app-title" content="Your App Title" />
        <meta name="application-name" content="Your App Title" />

        <meta name="msapplication-TileColor" content="#ffffff" />
        <meta name="msapplication-TileImage" content="URL to your tile image" />
        <meta name="theme-color" content="#ffffff" />

        <link rel="icon" href="/favicon.ico" />
        <link rel="apple-touch-icon" href="/apple-touch-icon.png" />
        <link rel="manifest" href="/manifest.json" />
        <link rel="canonical" href="https://calidtech.com/EnterpriseDataCenterConsultingSubServicePage" />
      </Helmet>
      <EnterpriseHeader />
      <EnterpriseServicePageNavBar />
      <header className="enterprise-subservice-header-datacenter">
        <div className="enterprise-subheader-text">
          <h1 className="enterprise-subservice-header-heading">
            <span id="enterprise-subservice-header-subheading">
              {" "}
              Data Center Consulting Services Across Major Indian Cities






              {" "}
            </span>
          </h1>
          <p className="enterprise-subservice-header-description">
            Data Centre Consulting involves the provision of expert guidance and
            advice to organizations regarding the design, implementation,
            management, and optimization of data center infrastructure and
            operations.
          </p>
          <div className="button-container">
            <a
              href={OnPremiseDC}
              download="On-PremiseDC.pptx"
              className="Downloadpdf"
            >
              Get PDF
            </a>
            <button onClick={toggleEnquiryPopup} className="servicesenquiry">
              Enquiry
            </button>
          </div>
        </div>
      </header>
      {/* header end */}

      <div className="enterprise-servive-des">
        <h2 className="main-heading-entire-site">
        Data centre design and Architecture,Infrastructure Planning,Scalability,Security and compliance.
        </h2>
        <p>
          Calid Technologies provide Data Center Consulting services across India, covering major cities like Mumbai, Delhi, Bangalore, Hyderabad, Chennai, Kolkata, Pune, and Ahmedabad. Our comprehensive consulting services help design and optimize data centers to ensure peak performance, scalability, and energy efficiency. By following industry best practices and standards, we enable reliable, secure, and cost-effective data center solutions tailored to meet evolving business needs, with expertise accessible throughout India’s key urban centers.
        </p>
      </div>

      <div className="enterprise-subservice-card-div-container">
        <div
          className="enterprise-subservice-card-div"
          style={{ "--clr": "#009688" }}
        >
          <div className="enterprise-subservice-card-img-box">
            <img src={ComprehensiveAssessment} alt="Comprehensive Assessment" />
          </div>
          <div className="enterprise-subservice-card-div-content">
            <h2 className="enterprise-subservice-card-div-heading">
            Data centre design and Architecture.
            </h2>
            <p className="enterprise-subservice-card-div-description">
              Data Centre Consulting involves conducting a thorough evaluation
              of the existing data center infrastructure, including hardware,
              software, networking, and security systems.
            </p>
          </div>
        </div>
        <div
          className="enterprise-subservice-card-div"
          style={{ "--clr": "#FF3E7F" }}
        >
          <div className="enterprise-subservice-card-img-box">
            <img
              src={StrategicPlanningAndDesign}
              alt="Strategic Planning And Design"
            />
          </div>
          <div className="enterprise-subservice-card-div-content">
            <h2 className="enterprise-subservice-card-div-heading">
            Infrastructure Planning
            </h2>
            <p className="enterprise-subservice-card-div-description">
              Consultants in this field work closely with organizations to
              develop tailored data center strategies aligned with their
              business objectives and future growth plans.
            </p>
          </div>
        </div>
        <div
          className="enterprise-subservice-card-div"
          style={{ "--clr": "#03A9F4" }}
        >
          <div className="enterprise-subservice-card-img-box">
            <img
              src={OptimizationAndEfficiencyImprovement}
              alt="Optimization And Efficiency Improvement"
            />
          </div>
          <div className="enterprise-subservice-card-div-content">
            <h2 className="enterprise-subservice-card-div-heading">
            Scalability
            </h2>
            <p className="enterprise-subservice-card-div-description">
              Data Centre Consultants focus on optimizing data center operations
              to improve efficiency, reduce costs, and enhance performance.
            </p>
          </div>
        </div>
        <EnquiryPopup
          isOpen={isEnquiryPopupOpen}
          onClose={toggleEnquiryPopup}
        />
      </div>
      {/* <ContactUsBot /> */}
      <CallMePopup />
      <ShowMoreIcons />
      {/* <Whatsapp /> */}

      {/* <ContactButtons /> */}

      <SwitchtoManufacturing />
      <EnterpriseFooter />
    </div>
  );
}

export default EnterpriseDataCenterConsultingSubServicePage;
